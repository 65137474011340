import React from "react"

const NotFoundPage = () => (
  <div className="container mx-auto p-4 m-4 h-screen ">
    <h1 className="font-sans text-4xl text-purple-800 py-2 text-center">
      About
    </h1>
  </div>
)

export default NotFoundPage
